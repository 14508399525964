import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './hamburger.css';

class NavBar extends React.Component {
      render () {
        return (
            <Menu right noOverlay>
                <a id="home" className="menu-item" href="/">Home</a>
                <a id="about" className="menu-item" href="/about">About</a>
                <a id="contact" className="menu-item" href="/contact">Contact</a>
                <a id="privacy" className="menu-item" href="/privacy">Privacy</a>
            </Menu>
        );
      }
}

export default NavBar;