import React from 'react';
import "./NoResults.css";

class NoResults extends React.Component {
    render() {
        return(
            <div>
                <h6 className="app-header">Find Me Food</h6>
                <h1>Uh oh!</h1>
                <p className="description">We couldn't find any food for you</p>
                <p className="description">Try widening your search and try again</p>
                <button type="button" className="search-btn" onClick={this.props.returnToSearch}>Search Again</button>
            </div>
        )
    }
}

export default NoResults;