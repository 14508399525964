import React from 'react';
import './About.css';


class About extends React.Component {
    render() {
        return(
            <div>
                <p><a className="app-header" href="/"> Find Me Food</a></p>
                <h1 id="page-header">About</h1>
                <div className="about-body">
                    <p>Thanks for using Find Me Food!</p>
                    <p>
                        This app was developed to solve the problem of never knowing where to go out to eat.
                        Gone are the days of getting into fights with your girlfriend/boyfriend just because neither of you can decide where to eat because you're both hangry.
                        Just pull open Find Me Food and let the internet decide for you! Now if you don't like where you end up eating you can be mad at the internet instead of each other.
                        You're welcome for saving your relationship.
                    </p>
                    <p className="section-header">
                        How to Use
                    </p>
                    <p>
                        This app should be super simple to use. Just go to the homepage at <a className="site-link" href="/">findmefood.co</a>, enter what kind of preferences you have (if any), and click the big red button that says "Find Me Food!"
                        We then search for all the restaurants in the area that match what you're looking for and return one result for you! If you don't like our choice you can click the "Rerun" button under the map and we'll pick a new place for you with exactly the same search criteria as your last search.
                        If you want to change your search preferences, just go back to the homescreen by pressing the "Back to Search" button, and enter your new preferences.
                    </p>
                </div>
            </div>
        )
    }
}

export default About;