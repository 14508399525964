import React from 'react';
import './Answer.css';
/* global google */

class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choice: this.props.choice,
            name: this.props.choice.name,
            vicinity: this.props.choice.vicinity,
            details: {},
            choicesShown: [
                this.props.choice
            ]
        }
        this.initMap = this.initMap.bind(this);
        this.detectDevice = this.detectDevice.bind(this);
        this.getDirections = this.getDirections.bind(this);
        this.checkOpenExists = this.checkOpenExists.bind(this);
        this.checkOpen = this.checkOpen.bind(this);
        this.rerunSearch = this.rerunSearch.bind(this);
        this.checkPrice = this.checkPrice.bind(this);
        this.checkRating = this.checkRating.bind(this);
        this.checkPhone = this.checkPhone.bind(this);
        this.checkWebsite = this.checkWebsite.bind(this);
        this.getDetails = this.getDetails.bind(this);
    }

    getDetails = async() => {
        let request = {
            placeId: this.state.choice.place_id
        };

        let service = new google.maps.places.PlacesService(document.createElement('div'));
        await service.getDetails(request, (placeDetails, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                // console.log(placeDetails);
                this.setState({
                    details: placeDetails
                }, () => {
                    this.initMap();
                })
                // return details;
            }
        });
    }

    initMap() {
        let myLatLng = {
            lat: this.state.choice.geometry.location.lat(),
            lng: this.state.choice.geometry.location.lng()
        };

        let map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 15
        });

        let marker = new google.maps.Marker({
            position: myLatLng,
            map: map
        });

        let contentString = '<div id="content">' +
            '<p id=info-restaurant-title>' + this.state.choice.name + '</p>' +
            '<p>' + this.state.choice.vicinity + '</p>' +
            this.checkPrice() +
            this.checkPhone() +
            this.checkWebsite() +
            '</div>';

        let infoWindow = new google.maps.InfoWindow({
            content: contentString
        });

        marker.addListener('click', function() {
            infoWindow.open(map, marker);
        });
    }

    checkPrice() {
        if (this.state.choice.price_level === 1) {
            return '<p>$</p>';
        } else if (this.state.choice.price_level === 2) {
            return '<p>$$</p>';
        } else if (this.state.choice.price_level === 3) {
            return '<p>$$$</p>';
        } else if (this.state.choice.price_level === 4) {
            return '<p>$$$$</p>';
        } else {
            // return '<p>Price not listed</p>';
            return "";
        }
    }

    checkRating() {
        if (this.state.choice.rating) {
            return '<p>' + this.state.choice.rating + '/5</p>';
        } else {
            // return '<p>No rating listed</p>';
            return "";
        }
    }

    checkPhone() {
        if (this.state.details.formatted_phone_number) {
            return '<p><a href="tel:' + this.state.details.formatted_phone_number + '">' + this.state.details.formatted_phone_number + '</a></p>';
        } else {
            return "";
        }
    }

    checkWebsite() {
        if (this.state.details.website) {
            return '<p><a href="' + this.state.details.website + '" target="_blank">Go to Website</a></p>';
        } else {
            return "";
        }
    }

    componentDidMount() {
        this.getDetails();
        // this.initMap();
    }

    detectDevice() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return "windows_phone";
        } else if (/android/i.test(userAgent)) {
            return "android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        } else {
            return "desktop";
        }
    }

    getDirections() {
        switch (this.detectDevice()) {
            case "desktop":
                window.open('https://www.google.com/maps/search/?api=1&query='+this.props.choice.name+'&query_place_id='+this.props.choice.place_id, '_blank');
                break;
            case "ios":
                // window.open('maps://www.google.com/maps/search/?api=1&query='+this.props.choice.name+'&query_place_id='+this.props.choice.place_id, '_blank');
                window.open('http://maps.apple.com/?ll='+this.props.choice.geometry.location.lat()+','+this.props.choice.geometry.location.lng()+'&q='+this.props.choice.name, '_blank');
                break;
            case "android":
                window.open('https://www.google.com/maps/search/?api=1&query='+this.props.choice.name+'&query_place_id='+this.props.choice.place_id, '_blank');
                break;
            case "windows_phone":
                window.open('https://www.google.com/maps/search/?api=1&query='+this.props.choice.name+'&query_place_id='+this.props.choice.place_id, '_blank');
                break;
            default:
                window.open('https://www.google.com/maps/search/?api=1&query='+this.props.choice.name+'&query_place_id='+this.props.choice.place_id, '_blank');
        }
    }

    //check if the function to check if the restaurant is open exists in the API reply message
    checkOpenExists() {
        if (typeof this.state.choice.opening_hours !== 'undefined') {
            return true;
        } else {
            return false;
        }
    }

    // check if the restaurant is open
    checkOpen() {
        if (this.state.choice.opening_hours.open_now) {
            return "Open Now";
        } else {
            return "Closed";
        }
    }

    rerunSearch() {
        let allResults = this.props.allResults;
        let randRest = allResults[Math.floor(Math.random() * allResults.length)];

        // Check to make sure result hasn't been shown yet (or cycle back to beginning)
        if (this.state.choicesShown.length === this.props.allResults.length) {
            this.setState({
                choicesShown: []      // Reset choices shown once all options have been shown
            }, function() {
                this.rerunSearch();
            });
        } else if (this.state.choicesShown.includes(randRest)) {
            this.rerunSearch();
        } else {
            let updateChoicesShown = this.state.choicesShown;
            updateChoicesShown.push(randRest);
            this.setState({
                choicesShown: updateChoicesShown,
                choice: randRest,
                name: randRest.name,
                vicinity: randRest.vicinity
            },
            function() {
                // this.initMap();
                this.getDetails();
            }
            );
            this.props.updateChoice(randRest);
        }
    }

    render() {
        return(
            <div>
                <h6 className="app-header">Find Me Food</h6>
                <p className="restaurant-name">{this.state.name}</p>
                <p className="restaurant-info">{this.state.vicinity}</p>
                { this.state.choice.rating
                    ? <p className="restaurant-info">{Math.round(this.state.choice.rating * 100)/100}/5</p>
                    : null
                }
                {this.checkOpenExists()
                    ? <p id="open-close" className="restaurant-info">{this.checkOpen()}</p>
                    : null
                }

                <div id="map"></div>
                <div className="btn-wrapper">
                    <button type="button" id="directions-btn" onClick={this.getDirections}>Get Directions</button>
                    <button type="button" id="rerun-btn" onClick={this.rerunSearch}>Rerun</button>
                    <button type="button" id="search-btn" onClick={this.props.returnToSearch}>Back to Search</button>
                </div>
            </div>
        )
    }
}

export default Answer;