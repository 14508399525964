import React from 'react';
import "./NoLocation.css";

class NoLocation extends React.Component {
    constructor(props){
        super(props);
        this.reloadPage = this.reloadPage.bind(this);
    }

    reloadPage() {
        window.location.reload()
    }

    render() {
        return(
            <div>
                <h6 className="app-header">Find Me Food</h6>
                <h1>Uh oh!</h1>
                <p className="description">We were unable to get your current location</p>
                <p className="description">Make sure you have your location turned on</p>
                <p className="description">Refresh the page and try again</p>
                <button type="button" className="search-btn" onClick={this.reloadPage}>Refresh Page</button>
            </div>
        )
    }
}

export default NoLocation;