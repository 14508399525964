import React from 'react';
import Search from '../Search/Search';
import Answer from '../Answer/Answer';
import NoResults from '../NoResults/NoResults';
import Loading from '../Loading/Loading';
import '../../App.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'search',
      choice: {
        name: '',
        vicinity: '',
        rating: 0
      },
      userLocation: {
        lat: 0,
        lng: 0
      },
      searchParams: {
        foodtype: 'all',
        radius: "oneMile",
        price: "3",
        rating: "3",
        chainCheck: false,
        openCheck: false
      },
      allResults: []
    }

    this.sendChoice = this.sendChoice.bind(this);
    this.sendLocation = this.sendLocation.bind(this);
    this.updateView = this.updateView.bind(this);
    this.returnToSearch = this.returnToSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.updateAllResults = this.updateAllResults.bind(this);
    this.updateChoice = this.updateChoice.bind(this);
  }

  sendChoice(choiceData) {
    this.setState({
      choice: choiceData
    })
  }

  sendLocation(latitude, longitude) {
    let userLoc = {
      lat: latitude,
      lng: longitude
    };
    this.setState({
      userLocation: userLoc
    })
  }

  updateView(newView) {
    this.setState({
      show: newView
    })
  }

  returnToSearch() {
    this.setState({
      show: 'search'
    })
  }

  handleSearchChange( param, desiredSearchVal ) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [param]: desiredSearchVal
      }
    });
}

updateAllResults( results ) {
  this.setState({
    allResults: results
  });
}

updateChoice( newChoice ) {
  this.setState({
    choice: newChoice
  });
}

  chooseComponent() {
    switch (this.state.show) {
      case 'search':
        return <Search sendChoice={this.sendChoice}
                sendLocation={this.sendLocation}
                updateView={this.updateView}
                searchParams={this.state.searchParams}
                handleSearchChange={this.handleSearchChange}
                updateAllResults={this.updateAllResults}
              />;
      case 'answer':
        return <Answer returnToSearch={this.returnToSearch}
                choice={this.state.choice}
                userLocation={this.state.userLocation}
                allResults={this.state.allResults}
                updateChoice={this.updateChoice}
              />;
      case 'no_response':
        return <NoResults updateView={this.updateView} returnToSearch={this.returnToSearch} />;
      case 'loading':
        return <Loading updateView={this.updateView} message="Selecting your restaurant" />
      default:
        return <Search sendChoice={this.sendChoice}
                sendLocation={this.sendLocation}
                updateView={this.updateView}
                searchParams={this.state.searchParams}
                handleSearchChange={this.handleSearchChange}
                updateAllResults={this.updateAllResults}
              />;
    }
  }

  render() {
    return (
      <div className="App">
        <div>
          {this.chooseComponent()}
        </div>
      </div>
    );
  }
}

export default Home;