import React from 'react';
import './Contact.css';


class Contact extends React.Component {
    render() {
        return(
            <div>
                <p><a className="app-header" href="/"> Find Me Food</a></p>
                <h1 id="page-header">Contact Us</h1>
                <div className="contact-body">
                    <p>We'd love to hear from you!</p>
                    <p>Whether you have feedback, features to add, want to report an error, or just let us know how much you love the app, send us an email at <a id="email-link" href="mailto:findmefoodapp@gmail.com">findmefoodapp@gmail.com</a></p>
                </div>
            </div>
        )
    }
}

export default Contact;