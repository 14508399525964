const chainRestaurants = [
    "99 Restaurant",
    "99 Restaurants",
    'A&W Restaurants',
    'A&W',
    "America's Incredible Pizza Company",
    "Applebee's",
    "Applebee's Grill + Bar",
    "Applebee's Grill and Bar",
    "Applebee's Grill & Bar",
    "Applebee's Bar and Grill",
    "Applebee's Bar + Grill",
    "Applebee's Bar & Grill",
    "Arby's",
    'Arctic Circle Restaurants',
    'Argo Tea',
    "Arthur Treacher's",
    'Atlanta Bread Company',
    'Au Bon Pain',
    "Auntie Anne's",
    "BJ's Restaurants",
    "BJ's Restaurant",
    "BJ's",
    'Back Yard Burgers',
    'Bahama Breeze',
    'Baja Fresh',
    'Bakers Square',
    'Bar Louie Restaurants',
    "Bar Louie",
    'Baskin-Robbins',
    "Beef O'Brady's",
    "Ben & Jerry's",
    'Benihana',
    "Bennigan's",
    "Bertucci's",
    'Big Boy Restaurants',
    "Big Boy",
    'Biggby Coffee',
    'Bill Miller Bar-B-Q Enterprises',
    'Black Angus Steakhouse',
    'Black Bear Diner',
    "Blake's Lotaburger",
    'Blaze Pizza',
    'Blimpie',
    'Bob Evans Restaurants',
    "Bob Evans",
    "Bojangles' Famous Chicken 'n Biscuits",
    "Bojangles",
    'Bonefish Grill',
    'Boomarang Diner',
    'Boston Market',
    "Braum's",
    'Bravo Brio Restaurant Group',
    "British Beer Company",
    "Brown's Chicken & Pasta",
    "Bruegger's",
    "Bruegger's Bagels",
    "Bruster's Ice Cream",
    'Bubba Gump Shrimp Company',
    'Buca di Beppo',
    'Buffalo Wild Wings',
    'Burger King',
    'Burger Street',
    'BurgerFi',
    'Burgerville',
    "Bush's Chicken",
    'CKE Restaurants',
    "CKE",
    'Cafe Rio',
    'California Pizza Kitchen',
    'California Tortilla',
    "Camille's Sidewalk Cafe",
    "Capriotti's",
    "Captain D's",
    'Caribou Coffee',
    "Carino's Italian",
    "Carl's Jr.",
    "Carrabba's Italian Grill",
    "Carrabba's",
    'Carvel (franchise)',
    "Carvel",
    'Charleys Philly Steaks',
    "Charley's",
    "Checkers and Rally's",
    "Cheddar's Scratch Kitchen",
    'Cheeburger Cheeburger',
    "Chester's International",
    'Chevys Fresh Mex',
    'Chick-fil-A',
    'Chicken Express',
    "Chili's",
    'Chipotle Mexican Grill',
    "Chipotle",
    "Chuck E. Cheese's",
    "Chuck E. Cheese",
    "Church's Chicken",
    "Chuy's",
    'Cicis',
    'Cinnabon',
    'Claim Jumper',
    'Cluck-U Chicken',
    'Coffee Beanery',
    'Cold Stone Creamery',
    'Cook Out (restaurant)',
    "Cook Out",
    "Cooper's Hawk Winery & Restaurants",
    "Cooper's Hawk",
    "Copeland's",
    'Corner Bakery Cafe',
    'Così (restaurant)',
    "Cosi",
    'Cousins Subs',
    'Cracker Barrel',
    "Cracker Barrel Old Country Store",
    'CraftWorks Holdings',
    "Culver's",
    "D'Angelo Grilled Sandwiches",
    "D'Angelo",
    "D'Angelo's",
    'Dairy Queen',
    "Daphne's Greek Cafe",
    "Dave & Buster's",
    'Daylight Donuts',
    "Del Frisco's Double Eagle Steak House",
    "Del Frisco's",
    'Del Taco',
    "Denny's",
    "DiBella's",
    "Dickey's Barbecue Pit",
    "Dippin' Dots",
    "Domino's Pizza",
    "Domino's",
    'Donatos Pizza',
    "Donatos",
    'Duck Donuts',
    "Dunkin' Donuts",
    "Dunkin'",
    'Dunn Brothers Coffee',
    'Dutch Bros. Coffee',
    'Earl of Sandwich (restaurant)',
    "Earl of Sandwich",
    'East of Chicago Pizza',
    "Eddie V's Prime Seafood",
    "Eegee's",
    'Einstein Bros. Bagels',
    'El Pollo Loco (United States)',
    "El Pollo Loco",
    'El Taco Tote',
    "Erbert & Gerbert's",
    "Famous Dave's",
    'Farmer Boys',
    'Fatburger',
    "Fazoli's",
    'Firehouse Subs',
    'First Watch (restaurant chain)',
    "First Watch",
    'Five Guys',
    "Five Guys Burgers and Fries",
    "Fleming's Prime Steakhouse & Wine Bar",
    "Fleming's",
    'Fogo de Chão',
    'Fosters Freeze',
    "Fox's Pizza Den",
    "Freddy's Frozen Custard & Steakburgers",
    'Freebirds World Burrito',
    "Friendly's",
    "Frisch's",
    'Fuddruckers',
    "Furr's",
    "Fuzzy's Taco Shop",
    "Gatti's Pizza",
    "Gino's Pizza and Spaghetti",
    "Giordano's Pizzeria",
    "Giordano's",
    "Gloria Jean's Coffees",
    "Godfather's Pizza",
    'Gold Star Chili',
    'Golden Chick',
    'Golden Corral',
    'Golden Spoon',
    "Graeter's",
    "Grandy's",
    'Great American Cookies',
    'Great Harvest Bread Company',
    'Great Wraps',
    'Ground Round',
    "Guthrie's",
    "Handel's Homemade Ice Cream & Yogurt",
    "Happy Joe's",
    "Happy's Pizza",
    'Hard Rock Cafe',
    'Hartz Chicken',
    "Heine Brothers'",
    'Honey Dew Donuts',
    'Hooters',
    'HopCat',
    'Hot Dog on a Stick',
    "Houlihan's",
    "Houston's Restaurant",
    'HuHot Mongolian Grill',
    'Huddle House',
    "Hungry Howie's Pizza",
    'Hurricane Grill & Wings',
    'Hwy 55 Burgers Shakes & Fries',
    'Häagen-Dazs',
    'IHOP',
    'In-N-Out Burger',
    "In-N-Out",
    'Insomnia Cookies',
    'Intelligentsia Coffee & Tea',
    'Islands (restaurant)',
    "Islands",
    "J. Alexander's",
    'Jack in the Box',
    "Jack's",
    'Jamba Juice',
    'James Coney Island',
    "Jason's Deli",
    "Jerry's Subs & Pizza",
    "Jersey Mike's Subs",
    "Jersey Mike's",
    "Jet's Pizza",
    "Jimmy Buffett's Margaritaville",
    "Jimmy John's",
    "Joe's Crab Shack",
    'Johnny Rockets',
    'Jollibee',
    'Juice It Up!',
    'KFC',
    "Ker's WingHouse Bar & Grill",
    'Kona Grill',
    'Krispy Kreme',
    'Krystal (restaurant)',
    "Krystal",
    'L&L Hawaiian Barbecue',
    'La Madeleine (restaurant chain)',
    "La Madeleine",
    'La Salsa',
    "Landry's, Inc.",
    'Lazy Dog Restaurant & Bar',
    'Le Pain Quotidien',
    "Lee's Famous Recipe Chicken",
    "Lee's Sandwiches",
    'Legal Sea Foods',
    'Lennys Grill & Subs',
    "Lion's Choice",
    'Little Caesars',
    "Logan's Roadhouse",
    "Long John Silver's",
    'LongHorn Steakhouse',
    "Longhorn",
    'Louisiana Famous Fried Chicken',
    "Luby's",
    "Lucille's Smokehouse Bar-B-Que",
    'MOD Pizza',
    "Maggiano's Little Italy",
    "MaggieMoo's Ice Cream and Treatery",
    'Maid-Rite',
    'Manchu Wok',
    'Marble Slab Creamery',
    "Marco's Pizza",
    "Margaritaville",
    "Margaritas",
    "Margaritas Mexican Restaurant",
    "Max & Erma's",
    "McAlister's Deli",
    "McCormick & Schmick's",
    "McDonald's",
    'Mellow Mushroom',
    "Menchie's Frozen Yogurt",
    'Miami Subs Grill',
    "Miller's Ale House",
    "Milo's Hamburgers",
    "Mimi's Cafe",
    "Moe's Southwest Grill",
    "Moe's",
    "Monical's Pizza",
    'Mooyah',
    "Morton's The Steakhouse",
    'Mrs. Fields',
    "Nathan's Famous",
    "Newk's Eatery",
    'Noodles & Company',
    "O'Charley's",
    'Oberweis Dairy',
    "Olga's Kitchen",
    'Olive Garden',
    "Olive Garden Italian Restaurant",
    'On the Border Mexican Grill & Cantina',
    "On the Border",
    'Orange Julius',
    "Original Tommy's",
    'Outback Steakhouse',
    "Outback",
    'Ovation Brands',
    "P. F. Chang's China Bistro",
    "P.F. Chang's",
    'Panda Express',
    'Panera Bread',
    "Panera",
    "Papa Gino's",
    "Papa John's Pizza",
    "Papa John's",
    "Papa Murphy's",
    'Pappas Restaurants',
    "Pappas",
    "Peet's Coffee",
    "Peet's",
    'Pei Wei Asian Diner',
    "Pei Wei",
    'Penguin Point (restaurant chain)',
    "Penguinb Point",
    'Penn Station (restaurant)',
    "Penn Stations",
    'Perkins Restaurant and Bakery',
    "Perkins",
    'Peter Piper Pizza',
    'Phở Hòa',
    "Pho Hoa",
    'Pick Up Stix',
    'Pieology',
    'Pinkberry',
    'Pita Pit',
    'Pizza Hut',
    'Pizza Hut Express',
    'Pizza Inn',
    'Pizza Ranch',
    'Planet Smoothie',
    'Pollo Campero',
    'Pollo Tropical',
    'Ponderosa and Bonanza Steakhouses',
    'Popeyes',
    'Port of Subs',
    "Portillo's Restaurants",
    "Portillo's",
    'Potbelly Sandwich Shop',
    'Pret a Manger',
    'Pretzelmaker',
    'Primo Hoagies',
    'Qdoba',
    'Quiznos',
    'Rainforest Cafe',
    "Raising Cane's Chicken Fingers",
    "Raising Cane's",
    'Rax Roast Beef',
    'Red Hot & Blue (restaurant)',
    "Red Hot & Blue",
    "Red Hot and Blue",
    'Red Lobster',
    'Red Mango',
    'Red Robin',
    'Red Robin Gourmet Burgers and Brews',
    "Rita's Italian Ice",
    'Robeks',
    "Romano's Macaroni Grill",
    "Rosati's Authentic Chicago Pizza",
    'Round Table Pizza',
    'Roy Rogers Restaurants',
    "Roy Rogers",
    "Roy's",
    "Rubio's Coastal Grill",
    'Ruby Tuesday (restaurant)',
    "Ruby Tuesday",
    "Ruby's Diner",
    'Rusty Bucket Restaurant & Tavern',
    "Rust Bucket",
    "Ruth's Chris Steak House",
    'Saladworks',
    "Salsarita's Fresh Mexican Grill",
    'Saltgrass Steak House',
    "Sandella's Flatbread Café",
    'Sbarro',
    "Schlotzsky's",
    'Seasons 52',
    "Seattle's Best Coffee",
    'Shake Shack',
    "Shakey's Pizza",
    "Shane's Rib Shack",
    "Shari's Cafe & Pies",
    'Shipley Do-Nuts',
    "Shoney's",
    "Shorty's",
    'Sizzler',
    'Skyline Chili',
    'Slim Chickens',
    'Smashburger',
    "Smithfield's Chicken 'N Bar-B-Q",
    'Smokey Bones',
    'Smoothie King',
    "Sneaky Pete's",
    'Sonic Drive-In',
    "Sonny's BBQ",
    'Souper Salad',
    'Souplantation',
    'Spangles (restaurant)',
    "'Spangles",
    'Starbucks',
    "Steak 'n Shake",
    'Steak Escape',
    'Stockade Companies',
    'Subway (restaurant)',
    "Subway Restaurants",
    "Subway Restaurant",
    "Subway",
    'Sweet Frog',
    'Sweetgreen',
    'Swensons',
    "T-Bones",
    "T-Bones Great American Eatery",
    'TCBY',
    'TGI Fridays',
    "TGI Friday",
    'Taco Bell',
    'Taco Bueno',
    'Taco Cabana',
    "Taco John's",
    'Taco Mayo',
    'Taco Palenque',
    'Taco Time',
    'Taco del Mar',
    'Tastee-Freez',
    "Ted's Montana Grill",
    'Texas Roadhouse',
    'Texas de Brazil',
    'The Capital Grille',
    'The Cheesecake Factory',
    'The Coffee Bean & Tea Leaf',
    'The Habit Burger Grill',
    'The Halal Guys',
    'The Melting Pot (restaurant)',
    "The Melting Pot",
    'The Old Spaghetti Factory',
    'The Original Pancake House',
    'Tijuana Flats',
    'Tim Hortons',
    "Togo's",
    "Tony Roma's",
    'Tropical Smoothie Cafe',
    'Tropical Sno',
    "Tubby's",
    "Tudor's Biscuit World",
    'Tutti Frutti Frozen Yogurt',
    'Twin Peaks (restaurant chain)',
    "Twin Peaks",
    'Umami Burger',
    "Uncle Maddio's Pizza Joint",
    'Uno Pizzeria & Grill',
    "Valentino's",
    'Veggie Grill',
    'Villa Restaurant Group',
    'Village Inn',
    'Waffle House',
    'Wahlburgers',
    "Wahoo's Fish Taco",
    'Wayback Burgers',
    "Wendy's",
    "Western Sizzlin'",
    "Wetzel's Pretzels",
    'Whataburger',
    'Which Wich?',
    'White Castle (restaurant)',
    "White Castle",
    'Wienerschnitzel',
    "Winchell's Donuts",
    'Wingstop',
    'Yard House',
    'Yogen Früz',
    'Yogurtland',
    'Yoshinoya',
    "Zaxby's",
    "Zip's Drive-in",
    'Zoës Kitchen'
];

export { chainRestaurants };