const excludedLocations = [
    '7-Eleven',
    'CBA',
    'Circle K',
    'FamilyMart',
    'Lawson',
    'OXXO',
    'Tesco Express',
    'Superstop',
    'Kwik Shop',
    'Kwik Fill',
    'Kwik Trip',
    'Mega-X',
    'Tiger Mart',
    'Shop Rite',
    'Cumberland Farms',
    'Tedeschi',
    'Tedeschi Food Shops',
    'Shell',
    'Speedway',
    'WaWa',
    'United Dairy Farmers',
    'Sheetz',
    'GetGo',
    'Pilot Flying J',
    'Pilot Food Mart',
    'Exxon',
    'E-Z Mart'
];

export { excludedLocations };