import React from 'react';
import NavBar from './components/NavBar/NavBar';
import About from './components/About/About';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Privacy from './components/Privacy/Privacy';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';


class App extends React.Component {
  render() {
    return (
      <Router>
        <NavBar />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>


        <div className="App"></div>


    </Router>
    );
  }
}

export default App;
