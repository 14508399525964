import React from 'react';
import Loading from '../Loading/Loading';
import NoLocation from '../NoLocation/NoLocation';
import './Search.css';
import { chainRestaurants} from '../../chainrestaurants';
import { excludedLocations } from '../../donotinclude';
/* global google */


let all_results = [];

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: 0,
            long: 0,
            choice: {},
            chainCheck: this.props.searchParams.chainCheck,
            openCheck: this.props.searchParams.openCheck,
            answerVisible: false,
            view: 'search',
            searchCounter: 0,
            searchButtonPressed: false
        }

        this.handleFoodTypeChange = this.handleFoodTypeChange.bind(this);
        this.handleRadiusChange = this.handleRadiusChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.runNearbySearch = this.runNearbySearch.bind(this);
        this.callback = this.callback.bind(this);
        this.pickRandom = this.pickRandom.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.locationSuccess = this.locationSuccess.bind(this);
        this.handleChainCheck = this.handleChainCheck.bind(this);
        this.handleOpenCheck = this.handleOpenCheck.bind(this);
        this.runSearchWrapper = this.runSearchWrapper.bind(this);
    }

    componentDidMount() {
        this.getLocation();
    }

    getLocation = async() => {
        await navigator.geolocation.getCurrentPosition(
            this.locationSuccess,
            (error) => console.log(new Date(), error),
                { enableHighAccuracy: false, timeout: 15000},
        );
    }

    locationSuccess(position) {
        this.setState({
            lat: position.coords.latitude,
            long: position.coords.longitude
        });
        this.props.sendLocation(this.state.lat, this.state.long);
    }

    runNearbySearch = async() => {
        all_results = [];  // reset array in case multiple requests

        // convert radius to meters for google places API
        let distances = {
            quarterMile: 402,
            halfMile: 805,
            oneMile: 1609,
            twoMiles: 3219,
            threeMiles: 4828,
            fiveMiles: 8047,
            tenMiles: 16093,
            fifteenMiles: 24140
        };

        const checkFoodType = () => {
            if (this.props.searchParams.foodtype === 'all') {
                return '';
            } else if (this.props.searchParams.foodtype === 'fastfood') {
                return "fast food";
            } else {
                return this.props.searchParams.foodtype;
            }
        }

        const checkCafe = () => {
            if (this.props.searchParams.foodtype === 'cafe') {
                return ['cafe'];
            } else if (this.props.searchParams.foodtype === 'bar'){
                return ['bar'];
            } else {
                return ['restaurant'];
            }
        }

        // Google Places API request parameters
        var request = {
            location: new google.maps.LatLng(this.state.lat, this.state.long),
            radius: distances[this.props.searchParams.radius],
            name: checkFoodType(),
            types: checkCafe(),
            maxprice: parseInt(this.props.searchParams.price),
            openNow: !this.state.openCheck            // Uncomment this line to only search for restaurants that are currently open
        };

        // console.log(request);

        // Run a nearby search request to get nearby restaurants
        var service = new google.maps.places.PlacesService(document.createElement('div'));
        await service.nearbySearch(request, this.callback)


        this.setState({
            answerVisible: true
        });
    }

    callback(results, status, pagetoken) {
        if (status === 'ZERO_RESULTS') {
            this.props.updateView('no_response');
        } else if (status === google.maps.places.PlacesServiceStatus.OK){
            // this.hideSearch();

            // this.props.updateView('loading');  // doesn't like state update to loading then update to answer. look in async function call react
            all_results.push.apply(all_results, results);  // Since callback run may run multiple times, push results to another array

            // If more than 20 results, run query again to get next set of 20 results
            // NOTE the Google Places API will only receive 20 places per query and maxes out at 60 total
            if (pagetoken.hasNextPage) {
                pagetoken.nextPage();
            } else {                                    // If no more results
                // remove unwanted values (e.g. convenience stores) from the results list
                let excludedLocationsAltered = excludedLocations.map(function(x) {
                    return x.replace(/[^A-Z0-9]+/ig, "").toLowerCase(); // get rid of special characters and make lowercase for better comparison
                });
                all_results = all_results.filter(function(el){
                    return !excludedLocationsAltered.includes(el.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase());
                });

                // Remove chain restaurants from options if the no chains box is checked
                if (this.state.chainCheck) {
                    let chainRestaurantsAltered = chainRestaurants.map(function(x) {
                        return x.replace(/[^A-Z0-9]+/ig, "").toLowerCase(); // get rid of special characters and make lowercase for better comparison
                    });
                    all_results = all_results.filter(function(el){
                        return !chainRestaurantsAltered.includes(el.name.replace(/[^A-Z0-9]+/ig, "").toLowerCase());
                    });
                }

                all_results = all_results.filter(function(option){
                    return option.types[0] !== "lodging"
                });

                let choice = this.pickRandom(all_results);  // randomly choose from list restaurant to go to
                if (choice.rating < parseInt(this.props.searchParams.rating)) {
                    while (choice.rating < parseInt(this.props.searchParams.rating)) {
                        choice = this.pickRandom(all_results);
                    }
                }
                this.props.updateAllResults(all_results);
                // console.log(all_results);
                // console.log(choice);
                // console.log('lat: ' + choice.geometry.location.lat());
                // console.log('long: ' + choice.geometry.location.lng());
                this.setState({
                    choice: choice
                });
                this.props.sendChoice(this.state.choice);
                this.props.updateView('answer');
            }
        }
    }

    runSearchWrapper = async() => {
        if (this.state.view !== "loading") {
            this.handleLoading();
        }
        if (this.state.long === 0 && this.state.searchCounter < 15) {
            let count = this.state.searchCounter + 1;
            this.setState({
                searchCounter: count
            });
            setTimeout(() => {
                this.runSearchWrapper()
            }, 1000);
        }  else if (this.state.searchCounter >= 15) {
            this.setState({
                view: "location_not_found"
            });
        } else {
            this.runNearbySearch();
        }
    }

    // Pick random element from array
    pickRandom(myArray){
        let randRest = myArray[Math.floor(Math.random() * myArray.length)];
        return randRest;
    }

    handleFoodTypeChange( event ) {
        this.props.handleSearchChange('foodtype', event.target.value)
    }

    handleRadiusChange( event ) {
        this.props.handleSearchChange('radius', event.target.value)
    }

    handlePriceChange( event ) {
        this.props.handleSearchChange('price', event.target.value)
    }

    handleRatingChange( event ) {
        this.props.handleSearchChange('rating', event.target.value)
    }

    handleLoading() {
        this.setState({
            view: 'loading'
        })
    }

    handleChainCheck() {
        if (document.getElementById("chainCheck").checked) {
            this.setState({
                chainCheck: true
            });
            this.props.handleSearchChange('chainCheck', true)
        } else {
            this.setState({
                chainCheck: false
            });
            this.props.handleSearchChange('chainCheck', false)
        }
    }

    handleOpenCheck() {
        if (document.getElementById("openCheck").checked) {
            this.setState({
                openCheck: true
            });
            this.props.handleSearchChange('openCheck', true)
        } else {
            this.setState({
                openCheck: false
            });
            this.props.handleSearchChange('openCheck', false)
        }
    }


    render() {
        if (this.state.view === 'loading') {
            let message = "";
            if (this.state.long === 0) {
                message = "Getting your location...";
            } else {
                message = "Picking a restaurant for you...";
            }
            return (
                <div>
                    <div className="title-box">
                        <h1 id="title_big">Find Me Food</h1>
                    </div>
                    <Loading message={message} />
                </div>
            )
        } else if (this.state.view === 'location_not_found') {
            return(
                <div>
                    <NoLocation />
                </div>
            )
        } else {
            return (
                <div className="Search">
                    <div className="title-box">
                        <h1 id="title_big">Find Me Food</h1>
                    </div>
                    <p id="description">Enter the options for the type of restaurant you want below, then press the Find Me Food button at the bottom and we'll choose where you eat!</p>
                    <form>
                        <div className="optionContainer">
                            <div className="label">
                                <label>Radius</label>
                            </div>
                            <div className="option">
                                <select value={this.props.searchParams.radius} onChange={this.handleRadiusChange} className="searchParam">
                                    <option value="quarterMile">1/4 Mile</option>
                                    <option value="halfMile">1/2 Mile</option>
                                    <option value="oneMile">1 Mile</option>
                                    <option value="twoMiles">2 Miles</option>
                                    <option value="threeMiles">3 Miles</option>
                                    <option value="fiveMiles">5 Miles</option>
                                    <option value="tenMiles">10 Miles</option>
                                    <option value="fifteenMiles">15 Miles</option>
                                </select>
                            </div>
                        </div>
                        <br></br>
                        <div className="optionContainer">
                            <div className="label">
                                <label>Food Type</label>
                            </div>
                            <div className="option">
                                <select value={this.props.searchParams.foodtype} onChange={this.handleFoodTypeChange} className="searchParam">
                                    <option value="all">All</option>
                                    <option value="asian">Chinese/Asian</option>
                                    <option value="italian">Italian</option>
                                    <option value="mexican">Mexican</option>
                                    <option value="indian">Indian</option>
                                    <option value="greek">Greek</option>
                                    <option value="cafe">Cafe</option>
                                    <option value="pub">Pub</option>
                                    <option value="pizza">Pizza</option>
                                    <option value="bar">Bar</option>
                                    <option value="fastfood">Fast Food</option>
                                    <option value="breakfast">Breakfast</option>
                                </select>
                            </div>
                        </div>
                        <br></br>
                        <div className="optionContainer">
                            <div className="label">
                                <label>Max Price</label>
                            </div>
                            <div className="option">
                                <select value={this.props.searchParams.price} onChange={this.handlePriceChange} className="searchParam">
                                    <option value="1">$</option>
                                    <option value="2">$$</option>
                                    <option value="3">$$$</option>
                                    <option value="4">$$$$</option>
                                </select>
                            </div>
                        </div>
                        <br></br>
                        <div className="optionContainer">
                            <div className="label">
                                <label>Min Rating</label>
                            </div>
                            <div className="option">
                                <select value={this.props.searchParams.rating} onChange={this.handleRatingChange} className="searchParam">
                                    <option value="1">1/5</option>
                                    <option value="2">2/5</option>
                                    <option value="3">3/5</option>
                                    <option value="4">4/5</option>
                                    <option value="5">5/5</option>
                                </select>
                            </div>
                        </div>
                        <div className="slider-container" id="chain-container">
                            <p className="chain-option-label">Include Chains</p>
                            <label className="switch no-highlight">
                                {this.props.searchParams.chainCheck
                                    ? <input id="chainCheck" type="checkbox" name="chainCheck" onChange={this.handleChainCheck} checked />
                                    : <input id="chainCheck" type="checkbox" name="chainCheck" onChange={this.handleChainCheck} />
                                }
                                <span className="slider round"></span>
                            </label>
                            <p className="chain-option-label">No Chains</p>
                        </div>
                        <div className="slider-container">
                            <p className="open-option-label">Open Now</p>
                            <label className="switch no-highlight">
                                {this.props.searchParams.openCheck
                                    ? <input id="openCheck" type="checkbox" name="openCheck" onChange={this.handleOpenCheck} checked />
                                    : <input id="openCheck" type="checkbox" name="openCheck" onChange={this.handleOpenCheck} />
                                }
                                <span className="slider round"></span>
                            </label>
                            <p className="open-option-label">Open or Closed</p>
                        </div>
                        <button type="button" onClick={this.runSearchWrapper} className="searchBtn">Find Me Food!</button>
                    </form>
                </div>
        )}
    }
}

export default Search;