import React from 'react';
import { BallBeat } from 'react-pure-loaders';
import "./Loading.css";

class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        }
    }

    render() {
        return(
            <div id="loading">
                <BallBeat id="loading-icon" color={'white'} loading={this.state.loading} />
                <p id="message">{this.props.message}</p>
            </div>
        )
    }
}

export default Loading;